<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex">
          <feather-icon icon="LayoutIcon" size="19"/>
          <h4 class="mb-0 ml-50">Toplantı Salonu Bilgileri</h4>
        </div>

        <b-row class="mt-1">

          <b-col cols="12" md="6" lg="6">
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="İsim" rules="required">
                  <b-form-group label="* İsim" :state="getValidationState(validationContext)">
                    <b-form-input trim placeholder="İsim" v-model="dataInfo.name"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="İşyeri" rules="required">
                  <b-form-group label="* İşyeri" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.workplaceId" :options="workplaceOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Kapasite">
                  <b-form-group label="Kapasite" :state="getValidationState(validationContext)">
                    <b-form-input trim placeholder="Kapasite" v-model="dataInfo.capacity"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Durum" rules="required">
                  <b-form-group label="Durum" label-for="isActive" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Açıklama">
                  <b-form-group label="Açıklama">
                    <b-form-textarea v-model="dataInfo.description" rows="2" placeholder="Açıklama..."/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" md="3" lg="3" class="d-flex flex-wrap justify-content-center align-content-center">
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-avatar
                    rounded button
                    size="200px"
                    ref="previewEl"
                    :src="(dataInfo.imageBase64 ? dataInfo.imageBase64 : getApiFile(dataInfo.imageUrl))"
                    variant="primary"
                    @click="$refs.refInputEl.click()">
                  <feather-icon icon="ImageIcon" size="150" v-if="dataInfo.imageBase64 === null && dataInfo.imageUrl === ''"/>
                </b-avatar>
                <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer">
              </b-col>
            </b-row>
          </b-col>

        </b-row>

        <div class="d-flex justify-content-between align-items-center border-bottom border-top pt-1 pr-1 pb-1">
          <div class="d-flex">
            <feather-icon icon="MonitorIcon" size="19"/>
            <h4 class="mb-0 mt-0 ml-50">Demirbaş Bilgileri</h4>
          </div>
          <b-button variant="primary" @click="newMaterialModal" size="md">
            <feather-icon icon="PlusSquareIcon" size="16"/>
            <span class="align-middle" role="button"> Ekle</span>
          </b-button>
        </div>

        <div v-for="(material, index) in dataInfo.materials" :key="index" class="d-flex justify-content-between align-items-center border-bottom py-1" v-if="!material.isDeleted">
          <div class="d-flex justify-content-between align-items-center">
            <b-avatar size="32" class="mr-50" :text="(index+1).toString()" :variant="`light-primary`"/>
            <div class="user-page-info">
              <h6 class="mb-0">
                {{ material.name }}
              </h6>
              <small class="text-muted">{{ material.description }}</small>
            </div>
          </div>
          <div>
            <b-button variant="warning" class="btn-icon" @click="editMaterial(material)">
              <feather-icon icon="EditIcon"/>
              Düzenle
            </b-button>
            <b-button variant="danger" class="btn-icon ml-1" @click="removeMaterial(material)">
              <feather-icon icon="XIcon"/>
              Çıkar
            </b-button>
          </div>
        </div>

        <b-modal v-model="modalShow" title="Demirbaş Bilgileri" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @show="resetModal" @hidden="resetModal" @ok="handleModal">
          <form ref="form" @submit.stop.prevent="submitModal">

            <b-form-group label="İsim">
              <b-form-input trim placeholder="İsim" v-model="materialInfo.name"/>
            </b-form-group>

            <b-form-group label="Açıklama">
              <b-form-input trim placeholder="Açıklama" v-model="materialInfo.description"/>
            </b-form-group>

            <b-form-group label="Miktar">
              <b-form-input trim placeholder="Miktar" v-model="materialInfo.amount"/>
            </b-form-group>

            <b-form-group label="Durum">
              <v-select v-model="materialInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
            </b-form-group>

          </form>
        </b-modal>

        <action-buttons :back-route="'meeting-room-list'"/>

      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {BAvatar, BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTags, BFormTextarea, BModal, BRow, BTable} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {email, required} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/meeting/room/store"
import workplaceModule from "@/views/organization/workplace/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import Vue from "vue"
import {useInputImageRenderer} from "@core/comp-functions/forms/form-utils";

export default {
  components: {
    BFormTags,
    BAvatar,
    BModal,
    BTable,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    AppCollapseItem,
    AppCollapse,
    ActionButtons,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_WORKPLACE_MODULE_NAME = 'store-workplace'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_WORKPLACE_MODULE_NAME, workplaceModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_WORKPLACE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const workplaceOptions = ref([])
    busy.value = true
    store.dispatch('store-workplace/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          workplaceOptions.value.push({label: value.shortTitle, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const dataInfo = ref({
      id: null,
      workplaceId: null,
      name: '',
      description: '',
      capacity: null,
      imageBase64: null,
      imageUrl: '',
      isActive: true,
      materials: []
    })

    const materialInfo = ref({
      id: 0,
      name: '',
      description: '',
      index: null,
      amount: null,
      isActive: true,
      isDeleted: false
    })

    const materialEditInfo = ref(null)
    const modalShow = ref(false)

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'meeting-room-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const newMaterialModal = () => {
      materialInfo.value = {
        id: 0,
        name: '',
        description: '',
        amount: null,
        index: null,
        isActive: true,
        isDeleted: false
      }

      modalShow.value = true
    }

    const resetModal = () => {

    }

    const submitModal = () => {
      let isAppendItem = true

      dataInfo.value.materials.forEach((value, index) => {
        if (value === materialEditInfo.value) {
          isAppendItem = false
          value.id = materialInfo.value.id
          value.name = materialInfo.value.name
          value.description = materialInfo.value.description
          value.amount = materialInfo.value.amount
          value.isActive = materialInfo.value.isActive
          value.isDeleted = materialInfo.value.isDeleted
          value.index = Math.floor(Math.random() * 112114115171)
        }
      });

      if (isAppendItem) {
        dataInfo.value.materials.push({
          id: materialInfo.value.id,
          name: materialInfo.value.name,
          description: materialInfo.value.description,
          amount: materialInfo.value.amount,
          isActive: materialInfo.value.isActive,
          isDeleted: materialInfo.value.isDeleted,
          index: Math.floor(Math.random() * 112114115171)
        })
      }

      materialInfo.value = {
        id: 0,
        name: '',
        description: '',
        amount: null,
        index: null,
        isActive: true,
        isDeleted: false
      }

      materialEditInfo.value = null

      modalShow.value = false
    }

    const handleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()

      if (materialInfo.value.name === '') {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'İsim zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      submitModal()
    }

    const removeMaterial = (materialData) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.materials.forEach((value, index) => {
            if (value === materialData) {
              value.isDeleted = true
            }
          });
        }
      })
    }

    const editMaterial = (materialData) => {
      materialEditInfo.value = materialData

      materialInfo.value = {
        id: materialData.id,
        name: materialData.name,
        description: materialData.description,
        amount: materialData.amount,
        isActive: materialData.isActive,
        isDeleted: materialData.isDeleted,
      };

      modalShow.value = true
    }

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'meeting-room-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const previewEl = ref(null)
    const refInputEl = ref(null)
    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      dataInfo.value.imageBase64 = base64
    })

    return {
      busy,
      dataInfo,
      refFormObserver,
      modalShow,
      materialInfo,
      workplaceOptions,
      previewEl,
      refInputEl,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      newMaterialModal,
      resetModal,
      submitModal,
      handleModal,
      removeMaterial,
      editMaterial,
      avatarText,
      getApiFile,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
